<template>
  <el-dialog
    title="预览"
    :visible.sync="visible"
    width="100%"
    top="0px"
    :before-close="closeOpen"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <div class="contentbox">
      <div class="formbox">
        <el-image :src="src"></el-image>
        <div slot="footer" class="dialog-footer">
          <el-button class="closeBtn" @click="closeOpen">取 消</el-button>
          <el-button type="primary" @click="saveadd">保 存</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      visible: false,
      src: '',

    }
  },
  mounted() {},
  methods: {
    /**
     * 打开dialog
     */
   async open() {
      this.$parent.$parent.$refs.baseSvg.deleteEit()
      this.visible = true

      var mainWarp = document.getElementById('svgMain')
      var serializer = new XMLSerializer()
      // 先把svg转一下，然后删除关闭按钮
      var svgMainString = serializer.serializeToString(mainWarp)
      var parser = new DOMParser()
      var svgMainElement = parser.parseFromString(svgMainString, 'image/svg+xml').documentElement
      var svgCloseGroup = svgMainElement.querySelectorAll('#svg_close_group')
      var svgBgRect = svgMainElement.querySelectorAll('#svg_bg')
      // 移除删除按钮
      svgCloseGroup.forEach(function(element) {
        element.remove()
      })
      var images = svgMainElement.getElementsByTagName('image')
      for(let i=0;i<images.length;i++){
        let url=images[i].getAttribute('href')    
        let src=await this.imageToBase64(url)
        images[i].setAttribute('href',src) 
      }
     var source = serializer.serializeToString(svgMainElement)
     var path = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(source)))
     this.src = path
    },
   imageToBase64(url) {  
    // 创建新的Image对象  
    return new Promise((resolve, reject) => {
       var Img = new Image(),
        dataURL = '';
        Img.src = url + '?v=' + Math.random();
        Img.setAttribute('crossOrigin', 'Anonymous');
        Img.onload = function() {
          var canvas = document.createElement('canvas'),
            width = Img.width,
            height = Img.height;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
          dataURL = canvas.toDataURL('image/png');
          resolve(dataURL)
        }; 
    })     
  },
    /**
     * 关闭dialog
     */
    closeOpen() {
      this.visible = false
    },
    /**
     * 关闭dialog
     */
    saveadd() {
      this.$parent.saveFile()
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
